<template>
    <div class="login-bg">
        <div class="container">
            <p class="header-text">E-COMMERCE DATA ENTRY</p>

            <div class="login-main-container">
                <div class="col-lg-5 login-field-container c-padding-40 center bg-white clearfix">
                    <p class="c-font-20 bold text-xs-center hidden-xs">Login</p>
                    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                        <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                            <div class="alert alert-danger" v-if="errorMessage" v-html="errorMessage"></div>
                            <div class="alert alert-info" v-if="info_message" v-html="info_message"></div>
                            <div class="col-12 input-effect c-margin-t-15 c-padding-0">

                                <validated-input alternative name="Username" class="mb-3 effect-20 text-white"
                                                 :rules="{required: true}" type="username"
                                                 label="Username" placeholder="Username" :disabled="loading"
                                                 v-model="model.username"></validated-input>
                            </div>
                            <div class="col-12 input-effect c-margin-t-15 c-padding-0">

                                <validated-input alternative name="Password" class="mb-3 effect-20 text-white"
                                                 :rules="{required: true}" type="password"
                                                 label="Password" placeholder="Password" :disabled="loading"
                                                 v-model="model.password"></validated-input>
                            </div>
                            <div class="text-center">
                                <base-button type="primary" native-type="submit" class="my-4 login-btn"
                                             :disabled="loading">
                                            <span v-show="loading">
                                                <span>Validating credentials</span>
                                            </span>
                                    <span v-show="!loading">Sign in</span>
                                </base-button>
                            </div>
                        </b-form>
                    </validation-observer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import { mapActions } from 'vuex';

export default {
    name: 'login',
    props: {
        info_message: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            loading: false,
            errorMessage: '',
            model: {
                username: '',
                password: ''
            }
        };
    },
    beforeDestroy () {
        document.body.classList.remove('background');
    },
    methods: {
        ...mapActions(['setUser']),
        onSubmit () {
            const formValidator = this.$refs.formValidator;
            const that = this;
            this.loading = true;
            axios.form(urls.auth.login, this.model).then(function (response) {
                const json = response.data;
                console.log(json);
                if (json.success === true) {
                    const redirectUrl = sessionStorage.getItem('redirectPath');
                    that.setUser(json.user);
                    that.$router.push(redirectUrl || { path: '/dashboard/' });
                } else {
                    formValidator.setErrors(json.errors);
                    that.errorMessage = json.errors.__all__ || '';
                }
                that.loading = false;
            }).catch(function () {
                that.errorMessage = 'Invalid credentials.';
                that.loading = false;
            });
        }
    }
};
</script>

<style scoped>
/*font*/

@font-face {
    font-family: 'Proxima Nova Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Proxima Nova Regular'), url('../../assets/font/ProximaNova-Regular.woff') format('woff');
}

/*font*/

body {
    font-family: 'Proxima Nova Regular';
    font-size: 14px;
}

.login-bg {
    background-image: url("../../assets/img/login-balloon.jpg");
    background-repeat: no-repeat;
    background-size: auto;
    height: 100vh;
}

.login-main-container {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}

.login-field-container {
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    box-shadow: 0 0 6px 0px #00000054;
}

.center {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}

.bg-theme {
    background: #004e96;
}

.bg-white {
    background: white;
}

.text-white {
    color: white;
}

.welcome-container {
    padding: 15px;
    border-bottom: 8px solid #fba631;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    box-shadow: 0 0 6px 0px #00000054;
}

.shadow {
    display: none;
}

.bold {
    font-weight: 600;
}

.c-padding-40 {
    padding: 40px
}

.c-padding-0 {
    padding: 0;
}

.c-margin-t-25 {
    margin-top: 25px;
}

.c-margin-t-15{
    margin-top: 15px;
}

.c-margin-t-30 {
    margin-top: 30px;
}

.c-margin-t-60 {
    margin-top: 60px;
}

.hidden-xs {
    display: none;
}

.login-main-container {
    width: 90%;
}

/*input*/

.col-3 {
    position: relative;
}

input[type="username"], input[type="password"] {
    color: #333;
    width: 100%;
    height: 42px;
    border: 1px solid #33333361;
    box-sizing: border-box;
    letter-spacing: 1px;
    border-radius: 5px;
    outline: none;
    padding: 13px 15px;
}

.login-btn {
    background: #004e96;
    border: none;
    color: white;
    padding: 8px 55px;
    font-size: 14px;
    border-radius: 5px;
    letter-spacing: 0.8px;
    text-align: center;
    width: 100%;
    margin-top: 30px;
    opacity: 0.99;
    box-shadow: 0px 0px 3px 5px #00000004;
    transition: 0.3s;
}

.login-btn:hover {
    box-shadow: 0px 0px 0px 5px #00000014;
    opacity: 1;
}

.header-text {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: white;
    text-shadow: 0px 0px 4px black;
    letter-spacing: 4px;
}

.text-xs-center {
    text-align: center;
}

.center-login-logo {
    width: 50%;
}

@media (min-width: 552px) {
    .center-login-logo {
        width: 25%;
    }

    .login-main-container {
        width: 50%;
    }
}

@media (min-width: 992px) {

    .login-main-container {
        width: 860px;
        display: inline-flex;
    }

    .welcome-container {
        border-bottom: 0;
        border-right: 8px solid #fba631;
        border-radius: 10px;
    }

    .login-field-container {
        border-radius: 10px;
    }

    .welcom-text {
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 45%;
    }

    .center-login-logo {
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
        width: 60%;
    }

    .header-text {
        font-size: 30px;
    }

    .login-btn {
        margin-top: 60px;
    }

    .shadow {
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: 2px;
        display: block;
    }

    .hidden-xs {
        display: block;
    }

}

@media (min-width: 1200px) {
    .login-bg {
        background-size: cover;
    }
}

@media (max-width: 320px) {

    .header-text {
        font-size: 20px;
    }

    .login-main-container {
        transform: unset;
        position: unset;
        width: 100%;
        margin-bottom: 40px;
    }
}
</style>
